import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  GeneratorForm,
  ProgramDocumentRead,
} from '@cat-ai-us-fe/code-generator/util';

@Injectable({
  providedIn: 'root',
})
export class GeneratorFormService {
  codeGroup = new FormGroup({
    codeSelectors: new FormGroup({
      code: new FormControl(null, Validators.required),
      referenceCode: new FormControl(
        {
          value: null,
          disabled: true,
        },
        Validators.required,
      ),
      codeDocument: new FormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
    }),
    prompt: new FormControl<string | null>(null, Validators.required),
    fieldsGroup: new FormGroup({
      title: new FormControl(null, Validators.required),
      scope: new FormControl({ value: null, disabled: true }),
      purpose: new FormControl({ value: null, disabled: true }),
      category: new FormControl(null, Validators.required),
      form_number: new FormControl(null, Validators.required),
      reference_code: new FormControl({ value: null, disabled: true }),
      department: new FormControl({ value: null, disabled: true }),
      effectiveDate: new FormControl({ value: null, disabled: true }),
      explorationDate: new FormControl({ value: null, disabled: true }),
      primaryResponsibility: new FormControl({ value: null, disabled: true }),
    }),
  });

  get value(): GeneratorForm {
    return this.codeGroup.value as unknown as GeneratorForm;
  }

  get invalid() {
    return this.codeGroup.invalid;
  }

  updateFormValidity() {
    this.codeGroup.markAllAsTouched();
  }

  patch(doc: ProgramDocumentRead) {
    const fieldsControl = this.codeGroup.controls.fieldsGroup;
    Object.keys(fieldsControl.value).forEach((key: string) => {
      if (key in doc) {
        fieldsControl.get(key)?.patchValue((doc as any)[key]);
      }
    });

    this.codeGroup.get('prompt')?.patchValue(doc.user_prompt || '');
    this.codeGroup.updateValueAndValidity();
  }
}
