import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  catchError,
  filter,
  finalize,
  map,
  repeat,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { GeneratorApiService } from '../services/generator-api/generator-api.service';
import * as actions from './documents.actions';
import { ReferenceCode } from '@cat-ai-us-fe/api';
import { Injectable } from '@angular/core';
import { EMPTY, of, throwError } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { GeneratorFormService } from '../services/generator-form/generator-form.service';
import { downloadDocument, printDocument } from '@cat-ai-us-fe/shared/util';
import { DataModel, ProgramDocument } from '@cat-ai-us-fe/code-generator/util';
export class DocumentsModel {
  codes!: DataModel<ReferenceCode[]>;
  referenceCodes!: DataModel<ReferenceCode[]>;
  documentCodes!: DataModel<ReferenceCode[]>;
  currentDocument!: DataModel<ProgramDocument>;
}

@State<DocumentsModel>({
  name: 'documents',
  defaults: {
    codes: {},
    referenceCodes: {},
    documentCodes: {},
    currentDocument: {},
  },
})
@Injectable()
export class DocumentsState {
  constructor(
    private service: GeneratorApiService,
    private formService: GeneratorFormService,
  ) {}

  @Selector() static codes(state: DocumentsModel) {
    return state.codes;
  }

  @Selector() static referenceCodes(state: DocumentsModel) {
    return state.referenceCodes;
  }

  @Selector() static documentCodes(state: DocumentsModel) {
    return state.documentCodes;
  }

  @Selector() static currentDocument(state: DocumentsModel) {
    return state.currentDocument;
  }

  @Action(actions.CodeGenerator.FetchDocument)
  fetchDocument(
    ctx: StateContext<DocumentsModel>,
    action: actions.CodeGenerator.FetchDocument,
  ) {
    ctx.patchState({ currentDocument: { loading: true } });
    return this.service.fetchDocumentVersion(action.documentCodeId).pipe(
      map((res) => {
        this.formService.patch(res);
        ctx.patchState({
          currentDocument: { loading: false, data: res.storeData },
        });
      }),
      catchError((error: unknown) =>
        throwError(() =>
          of({ currentDocument: { loading: false, error: error } }),
        ),
      ),
    );
  }

  @Action(actions.DocumentCodesGetter.FirstLevelCodes)
  fetchCodes(ctx: StateContext<DocumentsModel>) {
    ctx.patchState({ codes: { loading: true } });
    return this.service.fetchCodes().pipe(
      map((res) => {
        ctx.patchState({ codes: { loading: false, data: res } });
      }),
      catchError((error: unknown) =>
        throwError(() => of({ codes: { loading: false, error: error } })),
      ),
    );
  }

  @Action(actions.DocumentCodesGetter.SecondLevelCodes)
  fetchReferenceCodes(
    ctx: StateContext<DocumentsModel>,
    action: actions.DocumentCodesGetter.SecondLevelCodes,
  ) {
    ctx.patchState({ referenceCodes: { loading: true } });
    return this.service.fetchCodes(action.parent).pipe(
      map((res) => {
        ctx.patchState({ referenceCodes: { loading: false, data: res } });
      }),
      catchError((error) =>
        throwError(() =>
          of({
            referenceCodes: {
              loading: false,
              error: error,
            },
          }),
        ),
      ),
    );
  }

  @Action(actions.DocumentCodesGetter.ThirdLevelCodes)
  fetchDocumentCodes(
    ctx: StateContext<DocumentsModel>,
    action: actions.DocumentCodesGetter.ThirdLevelCodes,
  ) {
    ctx.patchState({ documentCodes: { loading: true } });
    return this.service.fetchCodes(action.parent).pipe(
      map((res) => {
        ctx.patchState({ documentCodes: { loading: false, data: res } });
      }),
      catchError((error: unknown) =>
        throwError(() =>
          of({ documentCodes: { loading: false, error: error } }),
        ),
      ),
    );
  }

  @Action(actions.CodeGenerator.CreateDocument)
  createInitialDocument(
    ctx: StateContext<DocumentsModel>,
    action: actions.CodeGenerator.CreateDocument,
  ) {
    return this.service.createDocument(action.documentCodeId).pipe(
      map((res: any) => {
        ctx.patchState({
          currentDocument: {
            data: { documentId: res.id as number, materialId: res.material },
          },
        });
      }),
      catchError((error: unknown) =>
        throwError(() =>
          of({ currentDocument: { loading: false, error: error } }),
        ),
      ),
    );
  }

  @Action(actions.CodeGenerator.CreateVersion)
  createVersion(ctx: StateContext<DocumentsModel>) {
    const documentId = ctx.getState().currentDocument.data
      ?.documentId as number;

    const patchState = (currentDocument: DataModel<ProgramDocument>) => {
      ctx.setState(
        patch({
          currentDocument: patch({
            data: patch({ ...currentDocument.data }),
            loading: currentDocument.loading,
            error: currentDocument.error,
          }),
        }),
      );
    };

    return this.service
      .createDocumentVersion(documentId, this.formService.value)
      .pipe(
        map((res) => {
          {
            patchState({
              data: { versionId: res.id, codeHTML: ' ' },
              loading: true,
            });
            return res.id;
          }
        }),
        catchError((err) => {
          patchState({ error: err });
          return EMPTY;
        }),
        finalize(() => {
          patchState({ loading: false });
        }),
      );
  }

  @Action(actions.CodeGenerator.CreateVersionWithText)
  createDocumentVersion(ctx: StateContext<DocumentsModel>) {
    const documentId = ctx.getState().currentDocument.data
      ?.documentId as number;

    const patchState = (currentDocument: DataModel<ProgramDocument>) => {
      ctx.setState(
        patch({
          currentDocument: patch({
            data: patch({ ...currentDocument.data }),
            loading: currentDocument.loading,
            error: currentDocument.error,
          }),
        }),
      );
    };

    patchState({ loading: true });

    return this.service
      .createDocumentVersion(documentId, this.formService.value)
      .pipe(
        map((res) => {
          {
            patchState({ data: { versionId: res.id }, loading: true });
            return res.id;
          }
        }),
        switchMap((versionId) =>
          this.service.createDocumentText(versionId).pipe(
            map((res) => {
              patchState({
                data: {
                  codeHTML: res.llm_original_response as string,
                },
              });
            }),
          ),
        ),
        catchError((err) => {
          patchState({ error: err });
          return EMPTY;
        }),
        finalize(() => {
          patchState({ loading: false });
        }),
      );
  }

  @Action(actions.CodeGenerator.UpdateText)
  updateDocumentText(
    ctx: StateContext<DocumentsModel>,
    action: actions.CodeGenerator.UpdateText,
  ) {
    ctx.setState(
      patch({
        currentDocument: patch({
          loading: true,
          data: patch({
            codeHTML: undefined,
          }),
        }),
      }),
    );

    return this.service
      .updateDocumentVersion(
        action.versionId,
        this.formService.value,
        action.markdown,
      )
      .pipe(
        map((res) => {
          ctx.setState(
            patch({
              currentDocument: patch({
                data: patch({
                  codeHTML: res.document_body as string,
                }),
                loading: false,
              }),
            }),
          );
        }),
      );
  }

  @Action(actions.CodeGenerator.Reset)
  resetCurrentDocument(ctx: StateContext<DocumentsModel>) {
    ctx.patchState({
      currentDocument: { data: {}, loading: false, error: undefined },
    });
  }

  @Action(actions.PdfDocument.Download)
  downloadPdfDocument(ctx: StateContext<DocumentsModel>) {
    this.formService.updateFormValidity();
    if (this.formService.invalid) {
      return;
    }
    const versionId = ctx.getState().currentDocument.data?.versionId as string;

    return this.service
      .fetchPdfDocument(versionId, this.formService.value)
      .pipe(switchMap((data) => of(downloadDocument(data.generated_file_url))));
  }

  @Action(actions.PdfDocument.Print)
  printPdfDocument(ctx: StateContext<DocumentsModel>) {
    this.formService.updateFormValidity();
    if (this.formService.invalid) {
      return;
    }
    const versionId = ctx.getState().currentDocument.data?.versionId as string;

    return this.service
      .fetchPdfDocument(versionId, this.formService.value)
      .pipe(switchMap((data) => of(printDocument(data))));
  }
}
