import { Injectable } from '@angular/core';
import {
  CheckTaskStatusService,
  DocumentCategoriesService,
  DocumentVersion,
  DocumentVersionRequest,
  DocumentVersionsGenerateCodeTextPartialUpdateRequestParams,
  DocumentVersionsPartialUpdateRequestParams,
  DocumentVersionsRetrieveRequestParams,
  DocumentVersionsService,
  DocumentsCreateRequestParams,
  DocumentsService,
  FormNumbersService,
  MaterialRequest,
  MaterialValidationsCreateRequestParams,
  MaterialValidationsService,
  MaterialsService,
  MaterialsUpdateRequestParams,
  PatchedMaterialValidationUpdateRequest,
  ReferenceCodesService,
} from '@cat-ai-us-fe/api';
import { filter, map, repeat, switchMap, take, tap } from 'rxjs';
import {
  GeneratorForm,
  ProgramDocumentRead,
} from '@cat-ai-us-fe/code-generator/util';

@Injectable({
  providedIn: 'root',
})
export class GeneratorApiService {
  constructor(
    private refCodesService: ReferenceCodesService,
    private documentsService: DocumentsService,
    private documentVersionsService: DocumentVersionsService,
    private formNumbersService: FormNumbersService,
    private documentCategoriesService: DocumentCategoriesService,
    private statusService: CheckTaskStatusService,
    private validationsService: MaterialValidationsService,
    private materialsService: MaterialsService,
  ) {}

  updateMaterial(params: MaterialsUpdateRequestParams) {
    return this.materialsService.materialsUpdate(params);
  }

  fetchDocumentVersion(id: string) {
    return this.documentVersionsService
      .documentVersionsRetrieve({ id })
      .pipe(map((d) => this.mapDocument(d)));
  }

  createMaterialValidations(params: MaterialValidationsCreateRequestParams) {
    return this.validationsService.materialValidationsCreate(params);
  }

  fetchCodes(parent?: number) {
    const params = parent ? { parent } : {};
    return this.refCodesService
      .referenceCodesList(params)
      .pipe(map((r) => r.results));
  }

  createDocument(documentId: number | null) {
    const params: DocumentsCreateRequestParams = {
      documentRequest: {
        reference_code: documentId,
        company_id: 1, // To be replaced
      },
    };
    return this.documentsService.documentsCreate(params);
  }

  createDocumentVersion(documentId: number, form: GeneratorForm) {
    const documentVersionRequest: DocumentVersionRequest = {
      document: documentId,
      custom_fields: form.fieldsGroup,
      title: form?.fieldsGroup?.title,
      category: form?.fieldsGroup?.category,
      form_number: form?.fieldsGroup?.form_number,
    };
    if (form.prompt) {
      Object.assign(documentVersionRequest, { user_prompt: form.prompt });
    }
    return this.documentVersionsService.documentVersionsCreate({
      documentVersionRequest,
    });
  }

  createDocumentText(versionId: string) {
    const params: DocumentVersionsGenerateCodeTextPartialUpdateRequestParams = {
      id: versionId,
    };

    return this.documentVersionsService
      .documentVersionsGenerateCodeTextPartialUpdate(params)
      .pipe(
        map((res) => res.task_id),
        filter((task_id): task_id is string => !!task_id),
        switchMap((task_id) => this.checkGeneratedDocumentStatus(task_id)),
        switchMap(() => this.fetchDocumentVersion(versionId)),
      );
  }

  private checkGeneratedDocumentStatus(taskId: string) {
    return this.statusService.checkTaskStatusRetrieve({ taskId }).pipe(
      repeat({ delay: 1000 }),
      tap((result) => {
        if (result.status == 'failure') {
          throw new Error(
            'Document generation is failed. Please, contact it support',
          );
        }
      }),
      filter((response) => response.status == 'success'),
      take(1),
    );
  }

  updateDocumentVersion(
    versionId: string,
    form: GeneratorForm,
    markdown?: string,
  ) {
    const params: DocumentVersionsPartialUpdateRequestParams = {
      id: versionId,
      patchedDocumentVersionRequest: {
        title: form.fieldsGroup.title || '',
        category: form.fieldsGroup.category,
        form_number: form.fieldsGroup.form_number,
        custom_fields: form.fieldsGroup,
      },
    };

    if (form.prompt) {
      params.patchedDocumentVersionRequest = {
        ...params.patchedDocumentVersionRequest,
        user_prompt: form.prompt || '',
      };
    }

    if (markdown) {
      params.patchedDocumentVersionRequest = {
        ...params.patchedDocumentVersionRequest,
        document_body: markdown,
      };
    }
    return this.documentVersionsService.documentVersionsPartialUpdate(
      params as any,
    );
  }

  fetchPdfDocument(versionId: string, form: GeneratorForm) {
    const getParams: DocumentVersionsRetrieveRequestParams = {
      id: versionId,
    };

    return this.updateDocumentVersion(versionId, form).pipe(
      switchMap(() =>
        this.documentVersionsService
          .documentVersionsRetrieve(getParams)
          .pipe(map((res: any) => res?.s3?.download_url)),
      ),
    );
  }

  fetchCategories() {
    return this.documentCategoriesService
      .documentCategoriesList({})
      .pipe(map((r) => r.results));
  }

  fetchFormNumbers() {
    return this.formNumbersService
      .formNumbersList({})
      .pipe(map((r) => r.results));
  }

  getMaterial(id: number) {
    return this.materialsService.materialsRetrieve({
      id,
    });
  }

  saveAsDraft(materialId: number) {
    return this.getMaterial(materialId).pipe(
      switchMap((material) =>
        this.createMaterialValidations({
          materialValidationRequest: {
            material: material.id,
            validated_by: material.created_by.id,
          },
        }),
      ),
    );
  }

  submitForApproval(materialId: number, title: string) {
    return this.updateMaterial({
      id: materialId,
      materialRequest: {
        object_id: materialId,
        type: MaterialRequest.TypeEnum.Document,
        status: MaterialRequest.StatusEnum.Created,
        title,
      },
    });
  }

  updateValidation(
    materialId: number,
    review: string,
    status: PatchedMaterialValidationUpdateRequest.StatusEnum,
  ) {
    return this.getMaterial(materialId).pipe(
      switchMap((material) =>
        this.validationsService.materialValidationsPartialUpdate({
          id: material.validations[0] && material.validations[0].id,
          patchedMaterialValidationUpdateRequest: {
            status,
            review,
          },
        }),
      ),
    );
  }

  private mapDocument(doc: DocumentVersion): ProgramDocumentRead {
    return {
      ...doc,
      storeData: {
        documentId: doc.document || (doc.document as any), // TODO: replace with doc.document.id when api will be updated
        versionId: doc?.id,
        codeHTML: doc?.text_body,
        materialId: (doc as any)?.material,
      },
    };
  }
}
