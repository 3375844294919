/* eslint-disable @typescript-eslint/no-empty-function */
export namespace DocumentCodesGetter {
  export class FirstLevelCodes {
    static readonly type = '[Documents] fetch codes';
    constructor() {}
  }

  export class SecondLevelCodes {
    static readonly type = '[Documents] fetch reference codes';
    constructor(public parent: number) {}
  }

  export class ThirdLevelCodes {
    static readonly type = '[Documents] fetch document codes';
    constructor(public parent: number) {}
  }
}

export namespace CodeGenerator {
  export class FetchDocument {
    static readonly type = '[Documents] get existing document';
    constructor(public documentCodeId: string) {}
  }
  export class CreateDocument {
    static readonly type = '[Documents] create initial document';
    constructor(public documentCodeId: number | null) {}
  }

  export class CreateVersion {
    static readonly type = '[Documents] create document version';
    constructor() {}
  }

  export class CreateVersionWithText {
    static readonly type =
      '[Documents] create document version ang generate text';
    constructor(public prompt: string) {}
  }

  export class UpdateText {
    static readonly type = '[Documents] update document text';
    constructor(
      public markdown: string | undefined,
      public versionId: string,
    ) {}
  }

  export class UpdateCustomFields {
    static readonly type = '[Documents] update document text';
    constructor(public customFields: object) {}
  }

  export class Reset {
    static readonly type = '[Documents] reset current document';
    constructor() {}
  }
}

export namespace PdfDocument {
  export class Download {
    static readonly type = '[Documents] download pdf document';
    constructor() {}
  }

  export class Print {
    static readonly type = '[Documents] print pdf document';
    constructor() {}
  }
}
